import clsx from "clsx";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

import { useLanguage } from "../../i18n/useLanguage";
import { useNullableDealerAccountInfo } from "../../stores/hooks/useNullableDealerAccountInfo";
import { useNullableGroupAccountInfo } from "../../stores/hooks/useNullableGroupAccountInfo";
import { useNullableNationalAccountInfo } from "../../stores/hooks/useNullableNationalAccountInfo";
import { Button } from "../../ui/Button/Button";
import { HomepageCarouselLink } from "./HomepageCarouselLink";
import { ProductPageHero } from "./ProductPageHero";

const getTranslateX = (index: number, selectedIndex: number): string => {
  const diff = index - selectedIndex;
  return `translateX(${diff * 100}%)`;
};

export const HomepageCarousel: FunctionComponent = () => {
  const [language] = useLanguage();
  const dealer = useNullableDealerAccountInfo();
  const national = useNullableNationalAccountInfo();
  const group = useNullableGroupAccountInfo();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [failedSrcs, setFailedSrcs] = useState<Set<string>>(new Set());

  const dealerCarousels =
    dealer?.dealerCarousels ||
    national?.nationalCarousels ||
    group?.dealerGroupCarousels;

  const validItems = useMemo(() => {
    return (
      dealerCarousels?.home_primary
        .filter(({ imageURL, resourceType }) => {
          const src = imageURL[language];
          const isFailed = failedSrcs.has(src);
          return resourceType === "image" && src && !isFailed;
        })
        .sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder)) ?? []
    );
  }, [dealerCarousels?.home_primary, failedSrcs, language]);

  const safeIndex = selectedIndex % validItems.length;
  const selectedItem = validItems[safeIndex];

  useEffect(() => {
    const rotationHoldDelayMS = Number(selectedItem?.rotationHoldDelayMS);
    if (!rotationHoldDelayMS) return;
    const timeout = setTimeout(() => {
      setSelectedIndex(safeIndex + 1);
    }, rotationHoldDelayMS);
    return () => {
      clearTimeout(timeout);
    };
  }, [selectedItem?.rotationHoldDelayMS, safeIndex]);

  if (!validItems.length) {
    return <ProductPageHero />;
  }

  return (
    <figure className="tw-carousel">
      {validItems.map((carouselItem, currentIndex) => {
        const src = carouselItem.imageURL[language];

        return (
          <div
            className={clsx(
              "tw-carousel__item",
              currentIndex === safeIndex ? "relative" : "absolute top-0 left-0"
            )}
            style={{
              transform: getTranslateX(currentIndex, safeIndex),
            }}
            key={`${src}-${currentIndex}`}
          >
            <img
              onError={(): void => {
                setFailedSrcs((prev) => new Set(prev.add(src)));
              }}
              alt="homepage carousel"
              className="tw-carousel__img"
              src={src}
            />
            <HomepageCarouselLink
              carouselItem={carouselItem}
              language={language}
            />
          </div>
        );
      })}
      {validItems.length > 1 && (
        <nav className="tw-carousel__controls">
          {validItems.map((carouselItem, currentIndex) => (
            <Button
              onClick={(): void => {
                setSelectedIndex(currentIndex);
              }}
              aria-label="carouselControl"
              className="tw-carousel__control"
              key={`${carouselItem.imageURL[language]}-${currentIndex}`}
            >
              <span
                className={clsx(
                  "tw-carousel__bullet",
                  safeIndex === currentIndex
                    ? "bg-opacity-100 scale-110"
                    : "bg-opacity-0 scale-0"
                )}
              />
            </Button>
          ))}
        </nav>
      )}
    </figure>
  );
};
