import clsx from "clsx";
import React, { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { partTypeTire } from "../../constants/partTypes";
import { TirePartItem } from "../../queries/useSearchTiresByVehicle";
import { useNullableDealerAccountInfo } from "../../stores/hooks/useNullableDealerAccountInfo";
import { AnyOrderItem, AnyPartItem } from "../../stores/slices/cartSlice";
import { ComboBox } from "../../ui/ComboBox/ComboBox";
import { Input } from "../../ui/Input/Input";
import { KeyValue } from "../../ui/KeyValue/KeyValue";
import { Placeholder } from "../../ui/Placeholder";
import { PriceDisplay } from "../../ui/PriceDisplay/PriceDisplay";
import { Spinner } from "../../ui/Spinner";
import { ChevronRightIcon } from "../../ui/icons/ChevronRightIcon";
import { getMsrp } from "../../util/getMsrp";
import { getTiresizeAndClass } from "../../util/getTireClassAndSize";
import { useAllowPartPricing } from "../../util/useAllowPartPricing";
import { getDefaultQuantity } from "./getDefaultQuantity";
import { PricingOption } from "./getFlatPricingOptions";
import { PriceSelectEntry } from "./usePriceSelectState";
import { usePricing } from "./usePricing";
import { usePricingLabelOverride } from "./usePricingLabelOverride";

type PriceSelectProps = {
  busy?: boolean;
  disabled?: boolean;
  isStaggered: boolean;
  itemLabourClassName?: string;
  itemPriceClassName?: string;
  itemTireSizeClassName?: string;
  keyClassName?: string;
  msrpClassName?: string;
  onChange?: (pricingOption: PriceSelectEntry[]) => void;
  onReady?: (pricingOption: PriceSelectEntry[]) => void;
  orderItem: AnyOrderItem | null;
  part: AnyPartItem | null;
  promotionClassName?: string;
  quantityClassName?: string;
  quantityInputClassName?: string;
  quantityKeyClassName?: string;
  rowClassName?: string;
  ruleClassName?: string;
  savingsClassName?: string;
  shouldDisplayRule?: boolean;
  tireSizeKeyClassName?: string;
  totalClassName?: string;
  valueClassName?: string;
};

const getSizeLabel = (isStaggered: boolean, index: number): string => {
  if (isStaggered) {
    return index === 0 ? "priceSelect.front" : "priceSelect.rear";
  }
  return "searchResults.tireSize";
};

export const PriceSelect: FunctionComponent<PriceSelectProps> = ({
  busy,
  disabled,
  isStaggered,
  itemLabourClassName,
  itemPriceClassName,
  itemTireSizeClassName,
  keyClassName,
  msrpClassName,
  onChange,
  onReady,
  orderItem,
  part,
  promotionClassName,
  quantityClassName,
  quantityInputClassName,
  quantityKeyClassName,
  rowClassName,
  ruleClassName = "my-2",
  savingsClassName,
  shouldDisplayRule,
  tireSizeKeyClassName,
  totalClassName,
  valueClassName,
}) => {
  const { t } = useTranslation();

  const { entries, total } = usePricing({
    defaultQuantity: getDefaultQuantity({ isStaggered, orderItem, part }),
    isStaggered,
    onReady,
    part,
  });

  const dealer = useNullableDealerAccountInfo();

  const primaryPriceOption: PricingOption | null = entries[0]?.[1] ?? null;

  const allowPricing = useAllowPartPricing(part, primaryPriceOption);

  const pricingLabelOverride = usePricingLabelOverride(part);

  if (!allowPricing) {
    return t("cart.contactDealerForPricing");
  }

  const shouldDisplayPromotion: boolean =
    Boolean(primaryPriceOption) &&
    primaryPriceOption.label !== primaryPriceOption.quantity;

  const savings = Number(primaryPriceOption?.context.displaySavings);

  // NOTE: V1 logic is at: code/client/components/product/ProductTile.jsx:4212
  //
  const shouldDisplayMsrp =
    !isStaggered &&
    dealer?.dealerEnableShowMsrpForItems &&
    !Number(dealer?.enableFinancing) &&
    savings;

  return (
    <>
      {entries.map(
        ([combinedPart, selectedPriceOption, setPricingIndex], key) => {
          const displayLabour =
            Number(selectedPriceOption.context.displayLabour) || 0;
          return (
            <Fragment key={combinedPart.itemId}>
              {key > 0 && <div className="w-full h-1" />}

              {shouldDisplayPromotion && (
                <div
                  className={clsx(
                    rowClassName,
                    promotionClassName,
                    "truncate font-light"
                  )}
                  title={selectedPriceOption.label}
                >
                  <span className={keyClassName}>
                    {selectedPriceOption.label}
                  </span>
                </div>
              )}

              {combinedPart.itemType === partTypeTire && (
                <KeyValue
                  className={clsx(rowClassName, itemTireSizeClassName)}
                  keyClassName={clsx(keyClassName, tireSizeKeyClassName)}
                  valueClassName={valueClassName}
                >
                  <>{t(getSizeLabel(isStaggered, key))}:</>
                  <>{getTiresizeAndClass(part as TirePartItem, key > 0)}</>
                </KeyValue>
              )}

              <KeyValue
                className={clsx(rowClassName, itemPriceClassName)}
                keyClassName={keyClassName}
                valueClassName={valueClassName}
              >
                <>{t(pricingLabelOverride || "priceSelect.price")}:</>
                <Placeholder className="w-[4em]" loading={!part}>
                  <PriceDisplay>
                    {selectedPriceOption.context.displayPrice}
                  </PriceDisplay>
                </Placeholder>
              </KeyValue>

              {Boolean(displayLabour) && (
                <KeyValue
                  className={clsx(rowClassName, itemLabourClassName)}
                  keyClassName={keyClassName}
                  valueClassName={valueClassName}
                >
                  <>{t("searchResults.installation")}:</>
                  <Placeholder className="w-[4em]" loading={!part}>
                    <PriceDisplay>
                      {displayLabour * Number(selectedPriceOption.quantity)}
                    </PriceDisplay>
                  </Placeholder>
                </KeyValue>
              )}

              {shouldDisplayMsrp && (
                <KeyValue className={msrpClassName}>
                  <Placeholder className="w-[3em]" loading={!part}>
                    {t("searchResults.msrp")}:
                  </Placeholder>
                  <Placeholder className="w-[3.6em]" loading={!part}>
                    <PriceDisplay>{getMsrp(part)}</PriceDisplay>
                  </Placeholder>
                </KeyValue>
              )}

              <KeyValue
                className={clsx(rowClassName, quantityClassName)}
                keyClassName={clsx(keyClassName, quantityKeyClassName)}
                valueClassName={valueClassName}
              >
                <>{t("priceSelect.quantity")}:</>
                <Placeholder className="w-[2em] text-3xl" loading={!part}>
                  {onChange ? (
                    <ComboBox
                      formatOption={(): string => {
                        return selectedPriceOption.quantity || "";
                      }}
                      onSelectOption={(option): void => {
                        if (!option) return;
                        setPricingIndex(option.value);
                        const currentEntry = entries[key];
                        const currentPricingOption =
                          currentEntry[0]?.pricingOptions.find(
                            ({ value }) => value === option.value
                          );
                        if (onChange && currentPricingOption) {
                          const selectedOptions = entries.map(
                            ([combinedPart, pricingOption]) => ({
                              combinedPart,
                              pricingOption,
                            })
                          );
                          selectedOptions[key].pricingOption =
                            currentPricingOption;
                          onChange(selectedOptions);
                        }
                      }}
                      aria-labelledby="price-options-label"
                      id={`price-options-${part?.partDetails.primaryPart.itemId}`}
                      options={combinedPart.pricingOptions}
                      preferredPlacement="bottom"
                    >
                      <Input
                        after={
                          busy ? (
                            <Spinner />
                          ) : (
                            <ChevronRightIcon className="rotate-90" />
                          )
                        }
                        className={clsx(
                          "h-8 w-[4rem] ml-1 pr-1 gap-1 -my-2",
                          quantityInputClassName
                        )}
                        aria-label="selectedQuantity"
                        disabled={disabled || busy}
                        inputMode="numeric"
                        readOnly
                        value={selectedPriceOption.quantity}
                      />
                    </ComboBox>
                  ) : (
                    selectedPriceOption.quantity
                  )}
                </Placeholder>
              </KeyValue>
            </Fragment>
          );
        }
      )}
      {shouldDisplayRule && <hr className={ruleClassName} />}
      <KeyValue
        className={clsx(rowClassName, totalClassName)}
        keyClassName={keyClassName}
        valueClassName={valueClassName}
      >
        <>{t("priceSelect.total")}:</>
        <Placeholder className="w-[3em]" loading={!part}>
          <PriceDisplay>{total}</PriceDisplay>
        </Placeholder>
      </KeyValue>
      {shouldDisplayMsrp && (
        <KeyValue
          className={clsx(rowClassName, savingsClassName)}
          keyClassName={keyClassName}
          valueClassName={clsx(valueClassName, "text-error")}
        >
          <>{t("priceSelect.savings")}:</>
          <Placeholder className="w-[4em]" loading={!part}>
            <PriceDisplay>
              {Number(savings) * Number(primaryPriceOption?.quantity)}
            </PriceDisplay>
          </Placeholder>
        </KeyValue>
      )}
    </>
  );
};
